import mixpanel from 'utils/mixpanel'
import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import PrivacyTermsModal from 'components/Modals/PrivacyTerms'
import UseTermsModal from 'components/Modals/UseTerms'
import SignatureTermsModal from 'components/Modals/SignatureTerms'
import SMSTermsModal from 'components/Modals/SMSTerms'

import Button from 'components/Button'
import { STATES_AVAILABLES } from 'constants'

export default function Location() {
    const navigate = useNavigate()

    const { state: userState, setState: setUserState } = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState('')

    const [privacyTermsVisible, setPrivacyTermsVisible] = useState(false)
    const [useTermsVisible, setUseTermsVisible] = useState(false)
    const [signatureTermsVisible, setSignatureTermsVisible] = useState(false)
    const [SMSTermsVisible, setSMSTermsVisible] = useState(false)

    useEffect(() => {
        setLocation(userState.location)
    }, [])

    const onContinue = () => {
        try {
            if (location === '') {
                return
            }

            setLoading(true)
            setUserState((prev) => ({
                ...prev,
                location
            }))

            mixpanel.track('Select State', {
                state:
                    location === STATES_AVAILABLES.puertoRico
                        ? 'Puerto Rico'
                        : 'Estados Unidos'
            })

            if (
                location === STATES_AVAILABLES.puertoRico ||
                location === STATES_AVAILABLES.florida
            ) {
                const redirect = sessionStorage.getItem('redirectCreateAccount')

                if (redirect) {
                    return navigate('/create-account')
                }

                navigate('/login')
            } else {
                navigate('/location/select-state')
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <PrivacyTermsModal
                isVisible={privacyTermsVisible}
                closeModal={() => setPrivacyTermsVisible(false)}
            />

            <UseTermsModal
                isVisible={useTermsVisible}
                closeModal={() => setUseTermsVisible(false)}
            />

            <SignatureTermsModal
                isVisible={signatureTermsVisible}
                closeModal={() => setSignatureTermsVisible(false)}
            />

            <SMSTermsModal
                isVisible={SMSTermsVisible}
                closeModal={() => setSMSTermsVisible(false)}
            />

            <div className="w-full min-h-full flex flex-col px-6 pb-10 pt-24">
                <div className="flex flex-col mb-10">
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                        ¿En dónde resides?
                    </h1>
                    <p className="mb-10">
                        Al elegir tu estado, podremos brindarte una mejor
                        experiencia.
                    </p>

                    <div className="flex flex-col gap-6">
                        <div className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id="PR"
                                name="state"
                                onChange={() => setLocation('Puerto Rico')}
                                checked={location === 'Puerto Rico'}
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor="PR"
                                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                Puerto Rico
                            </label>
                        </div>

                        <div className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id="us"
                                name="state"
                                onChange={() => setLocation('us')}
                                checked={location === 'us'}
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor="us"
                                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                Estados Unidos
                            </label>
                        </div>
                    </div>
                </div>

                <small className="block mt-auto mb-6">
                    Al continuar, estás de acuerdo con nuestra{' '}
                    <span
                        className="cursor-pointer text-blue-kiwi underline"
                        onClick={() => setPrivacyTermsVisible(true)}>
                        Política de privacidad
                    </span>
                    ,{' '}
                    <span
                        className="cursor-pointer text-blue-kiwi underline"
                        onClick={() => setUseTermsVisible(true)}>
                        Términos y condiciones de uso
                    </span>
                </small>

                <Button
                    className={`rounded-xl p-3 ${
                        location === ''
                            ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                            : 'bg-blue-kiwi text-white '
                    }`}
                    loading={loading}
                    onClick={onContinue}>
                    Continuar
                </Button>
            </div>
        </Fragment>
    )
}
