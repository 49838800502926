import React, { useContext, Fragment, useRef, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ProgressBar from 'components/ProgressBar'
import RepaymentDetails from 'templates/RepaymentDetails'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import mixpanel from 'utils/mixpanel'

export default function ApprovedLoanRepaymentDetails() {
    const {
        state: approvedState,
        saveState: saveApprovedState,
        initialState: initialApprovedState
    } = useContext(ApprovedLoanContext)

    const navigate = useNavigate()
    const repaymentRef = useRef()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const repayment = repaymentRef?.current

        if (searchParams.get('showTerms') === 'true') {
            repayment.setShowPaymentDay(false)
            repayment.setShowRepaymentTerms(true)
        }
    }, [])

    const onBackward = () => {
        const repayment = repaymentRef?.current

        if (repayment.showPaymentDay) {
            navigate('/approved-loan/amount')
        }

        if (repayment.showRepaymentTerms) {
            repayment.setShowPaymentDay(true)
            repayment.setShowRepaymentTerms(false)
        }
    }

    const onPaymentDaySuccess = (value) => {
        if (approvedState.paymentDay.value !== value) {
            saveApprovedState({
                ...initialApprovedState,
                amount: approvedState.amount,
                paymentDay: {
                    value,
                    completed: true
                }
            })

            mixpanel.track('Select Loan Payment Date', {
                'Loan Payment Date': value
            })
        }
    }

    const onRepaymentTermsSuccess = () => {
        saveApprovedState({
            ...approvedState,
            repaymentTerms: {
                ...approvedState.repaymentTerms,
                completed: true
            }
        })

        navigate('/approved-loan/payment-profile')
    }

    return (
        <Fragment>
            <ProgressBar progress="2/13" onBackward={onBackward} />
            <RepaymentDetails
                selectedPaymentDate={approvedState.paymentDay.value}
                ref={repaymentRef}
                onPaymentDaySuccess={onPaymentDaySuccess}
                onRepaymentTermsSuccess={onRepaymentTermsSuccess}
            />
        </Fragment>
    )
}
