import React, { useContext, useState, useEffect, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'
import { UserContext } from 'context/UserContext'
import { setBearerToken } from 'utils/http'
import { setBearerTokenFirebase } from 'utils/httpFirebase'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

import SuccessSVG from 'assets/onboarding_v3/success.svg'
import Button from 'components/Button'
import { UsersAPI } from 'api'

export default function CreatedAccountSuccess() {
    const navigate = useNavigate()

    const { setState: setAuthState } = useContext(AuthContext)
    const {
        setState: setUserState,
        state: userState,
        getUser
    } = useContext(UserContext)

    const [loading, setLoading] = useState(false)

    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    useEffect(() => {
        if (userState.user.id === undefined) {
            return navigate('/create-account')
        }
    }, [])

    const getDataUser = async () => {
        const {
            data: { user }
        } = await getUser(userState.user.id)

        setUserState((prev) => ({
            ...prev,
            user
        }))
    }

    const fingerprintSetData = async () => {
        try {
            await getDataFingerprint({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'create_account_success'
                }
            })

            await UsersAPI.fingerprintEvaluate()
            await getDataUser()
        } catch (error) {
            console.error(error)
        }
    }

    const onContinue = async () => {
        if (loading === true) {
            return
        }

        try {
            setLoading(true)
            const accessToken = userState.user.access_token
            const refreshToken = userState.user.refresh_token

            setBearerToken(accessToken)
            setBearerTokenFirebase(accessToken)

            getDataUser()

            setAuthState((prev) => ({
                ...prev,
                accessTokenApp: accessToken,
                accessTokenRefresh: refreshToken,
                logged: true
            }))
        } catch (error) {
            console.error(error)
        } finally {
            fingerprintSetData()
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SuccessSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Bienvenido a Kiwi!
                </h1>
                <p>
                    Tu cuenta está lista para usar, disfruta de todas las
                    funciones y beneficios que nuestra app tiene para ofrecerte.
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                loading={loading}
                onClick={onContinue}>
                Explorar
            </Button>
        </Fragment>
    )
}
