import delay from 'delay'
import React, { useState, useEffect, Fragment, useContext } from 'react'
import { Formik, Form, Field } from 'formik'
import { useNavigate } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'
import mixpanel from 'utils/mixpanel'

import LoadingModal from 'components/Modals/Loading'
import ProgressBar from 'components/ProgressBar'

export default function RequestLoanSeniority() {
    const navigate = useNavigate()

    const { state: requestState, saveState } = useContext(RequestLoanContext)
    const [loading, setLoading] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if (requestState.employer.completed === false) {
            return navigate('/request-loan/employer')
        }
    }, [])

    const onContinue = async ($event) => {
        if (loading === true) {
            return
        }

        try {
            setShowLoader(true)
            setLoading(true)

            await delay(300)

            saveState({
                ...requestState,
                seniority: {
                    value: $event.target.value,
                    completed: true
                }
            })

            mixpanel.track('Employment Term Survey', {
                'Job months': $event.target.value
            })

            navigate('/request-loan/bankruptcy')
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const onBackward = () => {
        navigate('/request-loan/employer')
    }

    const MONTHS_LIST = [
        '1 a 6 meses',
        '7 a 12 meses',
        '13 a 18 meses',
        '19 a 24 meses',
        'Más de 24 meses'
    ]

    const initialValues = {
        value: ''
    }

    return (
        <Formik initialValues={initialValues}>
            {() => (
                <Fragment>
                    <LoadingModal isVisible={showLoader} />

                    <ProgressBar progress="8/12" onBackward={onBackward} />

                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl">
                            ¿Cuántos meses tienes con tu fuente de ingreso
                            actual?
                        </h1>

                        <div className="flex flex-col gap-6 mb-10 mt-8">
                            {MONTHS_LIST.map((item) => (
                                <div className="relative" key={item}>
                                    <Field
                                        className="peer hidden"
                                        type="radio"
                                        id={item}
                                        name="value"
                                        value={item}
                                        onClick={onContinue}
                                    />
                                    <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                                    <label
                                        htmlFor={item}
                                        className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                        {item}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    )
}
