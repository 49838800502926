import mixpanel from 'utils/mixpanel'
import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { GAevent } from 'utils/GA'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'

import { UserContext } from 'context/UserContext'
import ProgressBar from 'components/ProgressBar'
import { CurrencyDollarIcon } from 'assets/icons_v3/fonts'
import Button from 'components/Button'
import { STATES_AVAILABLES } from 'constants'
import { DISBURSEMENT_METHODS } from 'constants'

export default function ApprovedLoanDisbursement() {
    const navigate = useNavigate()

    const { state: userState } = useContext(UserContext)
    const {
        state: approvedState,
        saveState: saveApprovedState,
        initialState: initialApprovedState
    } = useContext(ApprovedLoanContext)

    const [selectedDisbursement, setSelectedDisbursement] = useState(
        approvedState.disbursement.value
    )
    const [description, setDescription] = useState('')

    useEffect(() => {
        if (approvedState.paymentProfile.completed === false) {
            return onBackward()
        }
        onSelectDisbursement(approvedState.disbursement.value)
    }, [])

    const onContinue = () => {
        saveApprovedState({
            ...approvedState,
            disbursement: {
                value: selectedDisbursement,
                completed: true
            },

            /**
             * Se reinicia persona para vovler a calcular
             * si es mesalve y application es verified...
             */
            persona: {
                completed: false
            }
        })

        if (selectedDisbursement === DISBURSEMENT_METHODS.transfer) {
            mixpanel.track('Select Funding Method', {
                'Disbursement method': selectedDisbursement
            })
            navigate('/approved-loan/disbursement-profile')
        } else if (selectedDisbursement === DISBURSEMENT_METHODS.immediate) {
            GAevent('user_selection', 'select_me_salve')
            mixpanel.track('Select Funding Method', {
                'Disbursement method': selectedDisbursement
            })
            navigate('/approved-loan/stores')
        } else if (selectedDisbursement === DISBURSEMENT_METHODS.paperCheck) {
            navigate('/approved-loan/paper-check')
        }
    }

    const onBackward = () => {
        navigate('/approved-loan/repayment-details?showTerms=true')
    }

    const onSelectDisbursement = (disbursement) => {
        let description

        if (disbursement === DISBURSEMENT_METHODS.immediate) {
            description = `
                Este método le permite recoger el efectivo el mismo día dentro
                de uno de nuestros comercios participantes. Sólo está disponible
                dentro de los horarios de apertura del comercio. Es decir, si el
                comercio se encuentra cerrado, puede pasar a recoger el efectivo
                el próximo día laboral.
            `
        } else if (disbursement === DISBURSEMENT_METHODS.transfer) {
            description = `
                Las transacciones pueden revisarse caso por caso, lo que
                puede provocar retrasos.
            `
        } else if (disbursement === DISBURSEMENT_METHODS.paperCheck) {
            description = `
                Este método le permite recibir el dinero por medio de un cheque de papel que le llegará a su domicilio
            `
        }

        setDescription(description)
        setSelectedDisbursement(disbursement)
    }

    const getApprovedReason = () => {
        const {
            personal_loan: {
                application: { status }
            }
        } = userState.products

        const approvedList = status.filter(
            (status) => status.value === 'approved'
        )
        const currentApproved = approvedList[0]

        return [
            'plaid',
            'credit_score+plaid',
            'bank_statements',
            'credit_score+bank_statements'
        ].includes(currentApproved.reason)
    }

    const getSupportAccount = () => {
        const { support_account_number } = userState.user

        return !!support_account_number
    }

    const showMeSalveOption = () => {
        const { state } = userState.user
        const hasValidAmount = Number(approvedState.amount.value) <= 500
        const hasValidReason = getApprovedReason()
        const hasSupportAccount = getSupportAccount()
        const isFromPuertoRico = state === STATES_AVAILABLES.puertoRico

        return (
            hasValidAmount &&
            hasValidReason &&
            hasSupportAccount &&
            isFromPuertoRico
        )
    }

    const showPaperCheckOption = () => {
        return false
        const { state } = userState.user
        const isFromFlorida = state === STATES_AVAILABLES.florida
        return isFromFlorida
    }

    return (
        <Fragment>
            <ProgressBar progress="5/10" onBackward={onBackward} />

            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Selecciona el método de desembolso
                </h1>
                <p className="mb-10">
                    Elige cómo deseas recibir tus fondos. Disponemos de varias
                    opciones para ti
                </p>

                <div className="flex flex-col gap-6 mb-20">
                    <div className="relative">
                        <input
                            className="peer hidden"
                            type="radio"
                            id="transfer"
                            name="bank"
                            onChange={() =>
                                onSelectDisbursement(
                                    DISBURSEMENT_METHODS.transfer
                                )
                            }
                            checked={
                                selectedDisbursement ===
                                DISBURSEMENT_METHODS.transfer
                            }
                        />
                        <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                        <label
                            htmlFor="transfer"
                            className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                            <div className="leading-5 mb-1">
                                Transferencia bancaria
                            </div>
                            <p className="text-xs text-gray-500-kiwi">
                                1 - 3 días hábiles
                            </p>
                        </label>
                    </div>
                    {showPaperCheckOption() && (
                        <div className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id="paperCheck"
                                name="bank"
                                onChange={() =>
                                    onSelectDisbursement(
                                        DISBURSEMENT_METHODS.paperCheck
                                    )
                                }
                                checked={
                                    selectedDisbursement ===
                                    DISBURSEMENT_METHODS.paperCheck
                                }
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor="paperCheck"
                                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                <div className="leading-5 mb-1">
                                    Cheque de papel
                                </div>
                                <p className="text-xs text-gray-500-kiwi">
                                    10 - 15 días hábiles, requiere verificación
                                </p>
                            </label>
                        </div>
                    )}

                    {showMeSalveOption() && (
                        <div className="relative ">
                            <input
                                className="peer hidden"
                                type="radio"
                                id="immediate"
                                name="bank"
                                onChange={() =>
                                    onSelectDisbursement(
                                        DISBURSEMENT_METHODS.immediate
                                    )
                                }
                                checked={
                                    selectedDisbursement ===
                                    DISBURSEMENT_METHODS.immediate
                                }
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor="immediate"
                                className="cursor-pointer peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 block rounded-lg border border-solid border-gray-200-kiwi p-4">
                                <div className="leading-5 mb-1">
                                    Instantáneo
                                </div>
                                <p className="text-xs text-gray-500-kiwi">
                                    Recoger cash en tienda{' '}
                                    <strong>Me Salvé</strong>
                                </p>
                            </label>
                        </div>
                    )}
                </div>
            </div>

            <div className="mt-auto text-sm">
                <div className="flex items-center justify-between">
                    <div className="flex items-center mr-4">
                        <div className="h-10 w-10 bg-slate-100-kiwi mr-4 grow-0 shrink-0 rounded-full flex items-center justify-center">
                            <CurrencyDollarIcon className="w-6 text-regal-blue-kiwi" />
                        </div>
                        <div>Monto que vas a recibir</div>
                    </div>
                    <b>${approvedState.amount.value}</b>
                </div>

                {description && (
                    <p className="text-xs text-gray-500-kiwi mt-6">
                        {description}
                    </p>
                )}
            </div>

            <Button
                disabled={selectedDisbursement === ''}
                className={`rounded-xl p-3 mt-10 ${selectedDisbursement === ''
                    ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                    : 'bg-blue-kiwi text-white '
                    }`}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    )
}
