import React from 'react'
import { Outlet } from 'react-router-dom'

export default function CreateAccount() {
    return (
        <div className="flex flex-col w-full min-h-full px-6 pb-10 pt-24">
            <Outlet />
        </div>
    )
}
