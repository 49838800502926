import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'

export default function RequestLoanBankStatements() {
    const navigate = useNavigate()
    const { state: requestState } = useContext(RequestLoanContext)

    useEffect(() => {
        if (requestState.residence.completed === false) {
            return navigate('/request-loan/residence')
        }

        if (requestState.validation.completed === false) {
            return navigate('/request-loan/validation')
        }
    })

    return <Outlet />
}
