import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'

import mixpanel from 'utils/mixpanel'
import AddressTemplate from 'templates/Address'
import ProgressBar from 'components/ProgressBar'

export default function RequestLoanAddress() {
    const navigate = useNavigate()

    const onContinue = async (address) => {
        mixpanel.track('Residential Address Input')
        mixpanel.people.set({
            $city: address.city,
            'Survey City': address.city,
            State: address.state,
            Suite: address.suite,
            Address: address.value,
            Zipcode: address.zipcode
        })
        navigate('/request-loan/ssn')
    }

    const onBackward = () => {
        navigate('/home')
    }

    return (
        <Fragment>
            <ProgressBar progress="1/12" onBackward={onBackward} />

            <AddressTemplate
                context={RequestLoanContext}
                onContinue={onContinue}
            />
        </Fragment>
    )
}
