import { DISBURSEMENT_METHODS } from 'constants'
import mixpanel from './mixpanel'

export const PersonalLoanApplyRedirect = async (context = {}) => {
    if (context.reviewing?.completed) {
        return '/request-loan/reviewing'
    }

    if (context.validation?.completed) {
        const { value: selectedValue } = context.validation

        if (selectedValue === 'statements') {
            return '/request-loan/bank-statements'
        }

        if (selectedValue === 'plaid') {
            return '/request-loan/plaid'
        }
    }

    if (context.residence?.completed) {
        return '/request-loan/validation'
    }

    if (context.currentLoan?.completed) {
        return '/request-loan/residence'
    }

    if (context.bankruptcy?.completed) {
        return '/request-loan/current-loan'
    }

    if (context.seniority?.completed) {
        return '/request-loan/bankruptcy'
    }

    if (context.employer?.completed) {
        return '/request-loan/seniority'
    }

    if (context.military?.completed) {
        return '/request-loan/employer'
    }

    if (context.employment?.completed) {
        return '/request-loan/military'
    }

    if (context.income?.completed) {
        return '/request-loan/employment'
    }

    if (context.ssn?.completed) {
        return '/request-loan/income'
    }

    if (context.address?.completed) {
        return '/request-loan/ssn'
    }

    mixpanel.track('Apply Loan')
    return '/request-loan/address'
}

export const PersonalLoanApprovedRedirect = async (context = {}) => {
    if (context.persona?.completed) {
        return '/approved-loan/contract'
    }

    if (context.repaymentProfile?.completed) {
        return '/approved-loan/persona'
    }

    if (context.repaymentOption?.completed) {
        return '/approved-loan/repayment-profile'
    }

    if (
        context.disbursement?.completed &&
        context.disbursement.value === DISBURSEMENT_METHODS.paperCheck
    ) {
        if (context.paperCheckReviewing?.completed) {
            return '/approved-loan/repayment-options'
        }

        if (context.paperCheckPlaid?.completed) {
            return '/approved-loan/paper-check/reviewing'
        }

        return '/approved-loan/paper-check'
    }

    if (
        context.disbursement?.completed &&
        context.disbursement.value === DISBURSEMENT_METHODS.transfer
    ) {
        if (context.disbursementProfile?.completed) {
            return '/approved-loan/repayment-options'
        }

        return '/approved-loan/disbursement-profile'
    }

    if (
        context.disbursement?.completed &&
        context.disbursement.value === DISBURSEMENT_METHODS.immediate
    ) {
        if (context.stores?.completed) {
            return '/approved-loan/repayment-options'
        }

        return '/approved-loan/stores'
    }

    if (context.paymentProfile?.completed) {
        return '/approved-loan/disbursement'
    }

    if (context.repaymentTerms?.completed) {
        return '/approved-loan/payment-profile'
    }

    if (context.paymentDay?.completed) {
        return '/approved-loan/repayment-details?showTerms=true'
    }

    if (context.amount?.completed) {
        return '/approved-loan/repayment-details'
    }

    return '/approved-loan/amount'
}
