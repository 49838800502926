import React, { useEffect, useContext, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'

import MagnifyingSVG from 'assets/onboarding_v3/magnifying.svg'
import SuccessSendSVG from 'assets/onboarding_v3/success_send.svg'
import { UserContext } from 'context/UserContext'
import { DQ_STATUS } from 'constants'

import BackwardButton from 'components/Button/Backward'

export default function RequestLoanReviewing() {
    const navigate = useNavigate()

    const { state: requestState, saveState: requestSaveState } =
        useContext(RequestLoanContext)
    const { state: userState } = useContext(UserContext)

    const [onboarding, setOnboarding] = useState('')

    useEffect(() => {
        if (requestState.residence.completed === false) {
            return navigate('/request-loan/residence')
        }

        if (requestState.reviewing.completed === false) {
            requestSaveState({
                ...requestState,
                reviewing: {
                    completed: true
                }
            })
        }

        const {
            products: {
                personal_loan: { dq }
            }
        } = userState

        if (dq.status === DQ_STATUS.hard) {
            return setOnboarding('pending')
        }

        setOnboarding('success')
    }, [])

    const onContinue = () => {
        navigate('/home')
    }

    const currentDescription = {
        success: {
            image: SuccessSendSVG,
            title: '¡Solicitud enviada!',
            description:
                'Estamos analizando tu solicitud de préstamo, esto puede tardar entre 12 a 24 horas laborales. Siéntete libre de regresar en cualquier momento.'
        },
        pending: {
            image: MagnifyingSVG,
            title: 'Revisando solicitud',
            description:
                'Estamos analizando tu solicitud de préstamo, recibirás respuesta en minutos.'
        }
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onContinue} />

            <div className="flex flex-col justify-center h-full text-center m-auto">
                <img
                    className="mx-auto mb-8"
                    src={currentDescription[onboarding]?.image}
                />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    {currentDescription[onboarding]?.title}
                </h1>
                <p>{currentDescription[onboarding]?.description}</p>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onContinue}>
                Continuar
            </button>
        </Fragment>
    )
}
