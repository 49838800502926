import React, { useState, useContext, useEffect, Fragment } from 'react'
import { UserContext } from 'context/UserContext'

import Button from 'components/Button'
import { BankIcon } from 'assets/icons_v3/fonts'
import Advisement from 'components/Advisement'

export default function PaymentProfileSelectTemplate({
    title = 'Selecciona tu perfil de pago',
    primaryProfile,
    selectedProfile,
    checkingAccounts,
    setShowLoader,
    onSelectProfile
}) {
    const [accounts, setAccounts] = useState([])
    const [blockDisabledBankAccount, setBlockDisabledBankAccount] =
        useState(false)
    const [accountProfileID, setAccountProfileID] = useState(selectedProfile)
    const { updatePaymentProfile } = useContext(UserContext)

    useEffect(() => {
        checkAllDisabledAccounts()
        selectUniqueAccount()
    }, [])

    const selectUniqueAccount = () => {
        if(checkingAccounts.length === 1){
            onSelectAccountProfile(checkingAccounts[0]?.id)
        }
    }

    const checkAllDisabledAccounts = () => {
        const filterDisabledAccounts = checkingAccounts.filter(
            (profile) => profile.active === 0
        )

        const filterActiveAccounts = checkingAccounts.filter(
            (profile) => profile.active === 1
        )

        if (filterDisabledAccounts.length === checkingAccounts.length) {
            setBlockDisabledBankAccount(true)
            setAccounts(filterDisabledAccounts)
        } else {
            setAccounts(filterActiveAccounts)
        }
    }

    const onSubmit = async () => {
        if (accountProfileID === '') {
            return
        }

        try {
            setShowLoader(true)

            let paymentProfile = {
                id: '',
                bankName: '',
                last4: ''
            }

            if (primaryProfile.id === accountProfileID) {
                paymentProfile = {
                    id: primaryProfile.id,
                    bankName: primaryProfile.CheckingAccount.bankName,
                    last4: primaryProfile.CheckingAccount.accountNumber.slice(
                        -4
                    )
                }
            } else {
                const {
                    data: {
                        data: { last4 }
                    }
                } = await updatePaymentProfile({
                    primary: primaryProfile.id,
                    secondary: accountProfileID
                })
                const { bankName, last4: last4numbers } = last4

                paymentProfile = {
                    id: accountProfileID,
                    last4: last4numbers,
                    bankName
                }
            }

            onSelectProfile(paymentProfile)
        } catch (error) {
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const getAccountTitle = (item) => {
        return `Cuenta **** ${String(item.CheckingAccount.accountNumber).slice(
            -4
        )}`
    }

    const onSelectAccountProfile = (id) => {
        setAccountProfileID(id)
    }

    return (
        <Fragment>
            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-8 text-2xl">
                    {title}
                </h1>

                <div className="flex flex-col gap-6">
                    {accounts.map((profile) => (
                        <div key={profile.id} className="relative">
                            <input
                                className="peer hidden"
                                type="radio"
                                id={profile.title}
                                name={profile.title}
                                onChange={() =>
                                    onSelectAccountProfile(profile.id)
                                }
                                disabled={blockDisabledBankAccount}
                                checked={accountProfileID === profile.id}
                            />
                            <div className='pointer-events-none peer-checked:after:block after:hidden after:content-[""] after:absolute after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:block after:w-3 after:h-3 after:bg-[url("./assets/icons/check.svg")] after:bg-no-repeat after:bg-center after:bg-auto after:rounded-full absolute right-4 top-1/2 -translate-y-1/2 rounded-full w-5 h-5 peer-checked:border-blue-kiwi border border-solid border-gray-400-kiwi'></div>
                            <label
                                htmlFor={profile.title}
                                className={`min-h-[78px] flex cursor-pointer  rounded-lg border border-solid border-gray-200-kiwi p-4 ${
                                    blockDisabledBankAccount
                                        ? 'bg-gray-200-kiwi/50'
                                        : 'peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 '
                                }`}>
                                <div
                                    className={`h-10 w-10 mr-4 grow-0 shrink-0 rounded-full flex items-center justify-center ${
                                        blockDisabledBankAccount
                                            ? 'bg-gray-500-kiwi/10'
                                            : accountProfileID === profile.id
                                            ? 'bg-blue-50-kiwi'
                                            : 'bg-slate-100-kiwi'
                                    }`}>
                                    <BankIcon
                                        className={`w-6  ${
                                            accountProfileID === profile.id
                                                ? 'text-blue-kiwi'
                                                : 'text-regal-blue-kiwi'
                                        }`}
                                    />
                                </div>
                                <div className="my-auto">
                                    <div className="leading-5">
                                        {getAccountTitle(profile)}
                                    </div>
                                    {profile.isPrimary === 1 ? (
                                        <p className="text-xs text-gray-500-kiwi mt-1">
                                            Método de pago principal
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            {blockDisabledBankAccount ? (
                <Advisement className="mt-auto">
                    <strong>Cuenta de banco desactivada</strong>
                    <p>
                        No se puede vincular la cuenta de banco al préstamo, por
                        favor comunicarse con servicio al cliente
                    </p>
                </Advisement>
            ) : (
                <p className="text-xs text-gray-500-kiwi mt-auto">
                    Si quieres cambiar tu cuenta de banco puedes comunicarte con
                    nosotros al correo <strong>support@kiwicredito.com</strong>
                </p>
            )}
            <Button
                className={`rounded-xl p-3 mt-8 ${
                    accountProfileID === undefined ||
                    accountProfileID === '' ||
                    blockDisabledBankAccount
                        ? 'bg-gray-200-kiwi text-gray-500-kiwi'
                        : 'bg-blue-kiwi text-white '
                }`}
                disabled={blockDisabledBankAccount}
                onClick={onSubmit}>
                Continuar
            </Button>
        </Fragment>
    )
}
