import React, { useState, useEffect, Fragment, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { RequestLoanContext } from 'context/RequestLoanContext'
import mixpanel from 'utils/mixpanel'

import SSNTemplate from 'templates/SSN'
import ProgressBar from 'components/ProgressBar'
import Advisement from 'components/Advisement'
import ConsentTermsModal from 'components/Modals/ConsentTerms'
import PatriotaTermsModal from 'components/Modals/PatriotaTerms'
import ECBSVTermsModal from 'components/Modals/ECBSVTerms'
import CreditTermsModal from 'components/Modals/CreditTerms'
import { UserContext } from 'context/UserContext'
import { STATES_AVAILABLES } from 'constants'
import { UsersAPI } from 'api'

export default function RequestLoanSSN() {
    const navigate = useNavigate()
    const ECBSVTermsRef = useRef()

    const { state: userState } = useContext(UserContext)
    const { state: requestState, saveState: saveRequestState } =
        useContext(RequestLoanContext)

    const [consentTermsVisible, setConsentTermsVisible] = useState(false)
    const [patriotaTermsVisible, setPatriotaTermsVisible] = useState(false)
    const [creditTermsVisible, setCreditTermsVisible] = useState(false)

    useEffect(() => {
        if (requestState.address.completed === false) {
            return navigate('/request-loan/address')
        }
    }, [])

    const onContinue = async () => {
        if (
            userState.user.state === STATES_AVAILABLES.florida &&
            requestState.veritec?.completed === false
        ) {
            return navigate('/request-loan/veritec')
        }

        await saveCreditReport()

        mixpanel.track('SSN Verification')
        navigate('/request-loan/income')
    }

    const saveCreditReport = async () => {
        if (requestState.ssn.completed === false) {
            await UsersAPI.creditReport()

            await saveRequestState({
                ...requestState,
                ssn: {
                    completed: true
                }
            })
        }
    }

    const onBackward = () => {
        navigate('/request-loan/address')
    }

    const WarningSlot = (
        <Advisement className="mt-10" icon="shield_check">
            Verificar si eres aprobado es gratis y el 'soft-pull'{' '}
            <strong>no afectará</strong> tu puntaje de crédito.
        </Advisement>
    )

    const TermsSlot = (
        <p>
            Acepto que he leído y estoy de acuerdo con el{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setConsentTermsVisible(true)}>
                Consentimiento de verificación de identidad
            </span>
            ,{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => ECBSVTermsRef.current.openModal()}>
                Consentimiento de Verificación eCBSV
            </span>
            ,{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setCreditTermsVisible(true)}>
                Autorización de perfil de crédito
            </span>
            , y para Kiwi verificar mis ingresos. También la verificación de
            identidad nos garantizar el cumplimiento de requisitos de la{' '}
            <span
                className="text-blue-kiwi underline cursor-pointer"
                onClick={() => setPatriotaTermsVisible(true)}>
                Ley PATRIOTA de los EE.UU.
            </span>
        </p>
    )

    return (
        <Fragment>
            <ProgressBar progress="2/12" onBackward={onBackward} />

            <SSNTemplate
                context={RequestLoanContext}
                onContinue={onContinue}
                warningSlot={WarningSlot}
                termsSlot={TermsSlot}
                needCustomReport={true}
            />

            <ConsentTermsModal
                isVisible={consentTermsVisible}
                closeModal={() => setConsentTermsVisible(false)}
            />

            <PatriotaTermsModal
                isVisible={patriotaTermsVisible}
                closeModal={() => setPatriotaTermsVisible(false)}
            />

            <ECBSVTermsModal ref={ECBSVTermsRef} />

            <CreditTermsModal
                isVisible={creditTermsVisible}
                closeModal={() => setCreditTermsVisible(false)}
            />
        </Fragment>
    )
}
