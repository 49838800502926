export const TRACKING_EXPIRATION_DAYS = 9
export const EXPIRATION_CUPON_DAYS = 5

export const CREDIT_LIMIT_STATUS = {
    pending: 'pending',
    approved: 'approved',
    rejected: 'rejected',
    canceled: 'canceled'
}

export const CREDIT_BUILDER_STATUS = {
    current: 'current'
}

export const PERSONAL_LOAN_STATUS = {
    apply: 'apply',
    rejected: 'reject',
    approved: 'approved',
    current: 'current'
}

export const DQ_STATUS = {
    hard: 'HARD_DQ',
    soft: 'SOFT_DQ',
    approved: 'APPROVED_DQ',
    connectionFail: 'CONNECTION_FAIL_DQ'
}

export const STATES_AVAILABLES = {
    florida: 'Florida',
    puertoRico: 'Puerto Rico'
}

export const CREDIT_BUILDER_COURSE_STATUS = {
    active: 'active',
    paidout: 'paidout'
}

export const REFERRAL_AMOUNT = 15

export const SUPPORT_PHONE_NUMBER = 17874255991;
export const REPAYMENT_OPTIONS = {
    ACH: 'transfer',
    paperCheck: 'paper_check'
}

export const PAPER_CHECK_STATUS = {
    reviewing: 'call_paper_check_validation',
    validated: 'call_paper_check_validated'
}

export const DISBURSEMENT_METHODS = {
    transfer: 'transfer',
    immediate: 'immediate',
    paperCheck: 'paper_check'
}

export const DISBURSMENT_FUND_DAYS = {
    transfer: 2,
    paperCheck: 15
}

export const DISBURSEMENT_CHECK_STATUS = {
    pending: 'pending',
    resolved: 'resolved',
    rejected: 'rejected'
}

export const REPAYMENT_CHECK_STATUS = {
    pending: 'pending',
    resolved: 'resolved',
    rejected: 'rejected'
}

export const PAYMENT_PROFILE_STATUS = {
    pending: 'pending',
    validated: 'validated',
    failed: 'failed',
    forgotten: 'forgotten',
    error: 'error'
}

export const TIME_ZONE = {
    PR: 'America/Puerto_Rico'
}

export const PERSONA_STATUS = {
    approved: 'approved',
    retry: 'retry',
    declined: 'declined',
    failed: 'failed',
    needsReview: 'needs_review'
}

export const PRODUCTS = {
    creditBuilder: 'credit_builder',
    personalLoan: 'personal_loan'
}

export const APPLICATION_LAST_STATUS_VALUES = {
    verification: 'verification'
}

export const APPLICATION_LAST_STATUS_REASONS = {
    verified: 'verified'
}