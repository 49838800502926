import React, { useContext, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'

import ProgressBar from 'components/ProgressBar'
import PaymentProfile from 'templates/PaymentProfile'

export default function ApprovedLoanDisbursementProfile() {
    const navigate = useNavigate()

    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)

    useEffect(() => {
        if (approvedState.disbursement.completed === false) {
            return onBackward()
        }
    }, [])

    const onBackward = () => {
        navigate('/approved-loan/disbursement')
    }

    const onSelectProfile = ({ id, last4, bankName }) => {
        saveApprovedState({
            ...approvedState,
            disbursementProfile: {
                id,
                last4,
                bankName,
                completed: true
            }
        })

        navigate('/approved-loan/repayment-options')
    }

    return (
        <Fragment>
            <ProgressBar progress="6/10" onBackward={onBackward} />
            <PaymentProfile
                onSelectProfile={onSelectProfile}
                selectedProfile={approvedState.disbursementProfile.id ?? ''}
                selectProfileTitle="Selecciona tu perfil de desembolso"
            />
        </Fragment>
    )
}
