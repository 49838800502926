import React, { useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'

import ProgressBar from 'components/ProgressBar'
import PaymentProfile from 'templates/PaymentProfile'
import { DISBURSEMENT_METHODS } from 'constants'

export default function ApprovedLoanRepaymentProfile() {
    const navigate = useNavigate()

    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)

    const onBackward = () => {
        navigate('/approved-loan/repayment-options')
    }

    const onSelectProfile = ({ id, last4, bankName }) => {
        saveApprovedState({
            ...approvedState,
            repaymentProfile: {
                id,
                last4,
                bankName,
                completed: true
            }
        })

        navigate('/approved-loan/persona')
    }

    return (
        <Fragment>
            <ProgressBar progress="8/10" onBackward={onBackward} />
            <PaymentProfile
                onSelectProfile={onSelectProfile}
                selectedProfile={approvedState.repaymentProfile.id ?? ''}
                selectProfileTitle="Selecciona tu perfil de repago"
            />
        </Fragment>
    )
}
